<script lang="ts">
  import { onMount } from "svelte";
  import { fade, fly } from "svelte/transition";

  let spinning = false;

  onMount(() => {
    // we can use add event listener this component will not be remounted
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    document.addEventListener("astro:before-preparation", (ev: any) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      const originalLoader = ev.loader;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      ev.loader = async function () {
        spinning = true;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        await originalLoader();
        spinning = false;
      };
    });
  });
</script>

{#if spinning}
  <div
    in:fly={{ duration: 200 }}
    out:fade
    class="top-[0px] absolute w-[100vw] h-4 bg-black/40 animate-pulse"
  ></div>
{/if}
